export const faqs = [
    {
        question: "How do I start listening?",
        answer: "After downloading the Genti Audio app on your phone from the <a href='https://gentiaudio.app.link' target='__blank' class='faq-link cursor-pointer'>Google Play/Apple Store</a>,  sign up either with an email or with a Social Account.  Google, Facebook and Apple Sign up are available.  Want to check out the app first before signing up? No problem! Start listening from the “Explore Now” tab on the initial page of the app.",
    },
    {
        question: "Do I need to pay to use the app?",
        answer: "Several titles are free on the app.  Also, trailers and one or two episodes of paid content are free so you can check a title out.  Once you have confirmed you like the content and want to listen to more, you can unlock content either by watching ads (no payment necessary) or unlock by buying a coin bundle. You have multiple options.",
    },
    {
        question: "How do I get a coin?",
        answer: "You can get coins by watching ads. To do this, follow the prompt to watch videos when you try to unlock content.  Alternatively, to get coins, navigate to the <span class='fw-semibold'>“My Coins”</span> tab on the app. You will see various options. When you pick the most convenient one, it will lead you to different methods of payment, allowing you to choose the one that is most convenient for you. Check out how coins work on  <a href='/how-coins-work ' target='__blank' class='faq-link cursor-pointer'>gentimedia.com/how-coins-work </a>",
    },
    {
        question: "How can I listen offline?",
        answer: "You can download any audio from the download button beside each episode and listen at any time from the Genti Audio app from the Library> Downloaded tab. So go ahead! Download when you have internet and listen at any time even while your data is off or when you have a poor connection. Note: You can download any unlocked audio episode.",
    },

    {
        question: "How do I send my audio story or audio book on Genti Media?",
        answer: "We have a platform for creators that is easy to use. You can do so through  <a href='https://creator.gentimedia.com' target='__blank' class='faq-link cursor-pointer'>creator.gentimedia.com</a>  Sign up and upload your content easily.  Listener statistics and easy revenue withdrawal is also available.  Check out this detailed video walkthrough <a href='https://www.youtube.com/watch?v=rVRNtW4mACA' target='__blank' class='faq-link cursor-pointer'>HERE</a> to see how to navigate the Creator Portal.  Any issues? Send an email to our support email or WhatsApp to be guided accordingly.",
    },
    {
        question: "I have written works, how can I get them on Genti?",
        answer: "The self service portal is for existing audio works.  Writers can easily voice their works. Follow our LinkedIn and other socials to get prompted on upcoming Genti community events and Masterclasses where we can show you the basics of audio production. <br/> <br/> Do you want Genti to produce for you? Our production studio has limited capability but we can also connect you to Voice Talent in the Genti pool.  For our Production as a Service Rate card or to be connected directly to a voice talent from the Genti pool, send an email to <a href='mailto:support@gentimedia.com?subject=Production Request | [Summary of Ask]' target='__blank' class='faq-link cursor-pointer'>support@gentimedia.com</a> with subject link: Production Request | [Summary of Ask], and we will provide the rate card or connection accordingly."
        // Sign up and upload your content easily.  Listener statistics and easy revenue withdrawal is also available.  Check out this detailed video walkthrough <a href='https://www.youtube.com/watch?v=rVRNtW4mACA' target='__blank' class='faq-link cursor-pointer'>HERE</a> to see how to navigate the Creator Portal.  Any issues? Send an email to our support email or WhatsApp to be guided accordingly.",
    },
    {
        question: "How do I become a Voice Over Artist on Genti Media Talent Pool?",
        answer: "To be registered as a voice-over artist on Genti Media, kindly fill out the form below:  <a href='https://docs.google.com/forms/d/e/1FAIpQLSdSDvmMP3Nib8GLBpcl05BmKzc8dKlIj78KfLQalMadDiQjJQ/viewform' target='__blank' class='faq-link cursor-pointer'>Voice Over Artist Application Form.<a/> As mentioned in the previous question, we connect artists directly to those looking for voice talent to bilaterally agree on terms.  Also, paid Genti Studio gigs are available periodically."
    },

    // {
    //     question: "How do I become a Voice Over Artist on Genti Media?",
    //     answer: "To be a voice-over artist on Genti Media, kindly fill out the form below: <a href='https://docs.google.com/forms/d/e/1FAIpQLSdSDvmMP3Nib8GLBpcl05BmKzc8dKlIj78KfLQalMadDiQjJQ/viewform' target='__blank' class='faq-link cursor-pointer'>Voice Over Artist Application Form"
    // },
    // {
    //     question: "How do I become a writer for Genti Media?",
    //     answer: "You can do so through <a href='https://creator.gentimedia.com' class='faq-link'>creator.gentimedia.com</a>. Kindly send an email to our Content and Community Associate, Angela, at angela@gentimedia.com if you have any issues or further questions regarding the onboarding process. <br/> <p class='mt-3'>Below is a video of the Creator Portal Walkthrough to aid the process: <a href='https://youtu.be/ID4sJ_vx-G4' class='faq-link'>Creator Portal Walkthrough</a></p>"
    // }, {
    //     question: "How do I become a creator for Genti Media?",
    //     answer: "You can do so through <a href='https://creator.gentimedia.com' class='faq-link'>creator.gentimedia.com</a>. Kindly send an email to our Content and Community Associate, Angela, at angela@gentimedia.com if you have any issues or further questions regarding the onboarding process. <br/> <p class='mt-3'>Below is a video of the Creator Portal Walkthrough to aid the process: <a href='https://youtu.be/ID4sJ_vx-G4' class='faq-link'>Creator Portal Walkthrough</a></p>"

    // }, {
    //     question: "How do I get a coin?",
    //     answer: "To get coins, navigate to the My Coins tab on the app. You will see various options. When you pick the most convenient one, it will lead you to different methods of payment, allowing you to choose the one that is most convenient for you."
    // }, {
    //     question: "I bought coins and they have not reflected on my dashboard.",
    //     answer: "We apologize for the inconvenience encountered with the purchase of coins on the Genti Media Platform. Kindly reach out to us on WhatsApp through the chat link, sending your evidence of payment and email used alongside."
    // },
    // {
    //     question: "How do I send my story to Genti Media?",
    //     answer: "You can do so through <a href='https://creator.gentimedia.com' class='faq-link'>creator.gentimedia.com</a>. Kindly send an email to our Content and Community Associate, Angela, at angela@gentimedia.com if you have any issues or further questions regarding the onboarding process. <br/> <p class='mt-3'>Below is a video of the Creator Portal Walkthrough to aid the process: <a href='https://youtu.be/ID4sJ_vx-G4' class='faq-link'>Creator Portal Walkthrough</a></p>"

    // },

    // {
    //     question: "How can I listen offline?",
    //     answer: "To access the content offline, you would need to unlock the content through the purchase of coins, after which you can download the content of your choice."
    // }, {
    //     question: "I forgot my password, what can I do?",
    //     answer: "You can click on forgot password on the app and out in your email to reset your password"
    // },

]