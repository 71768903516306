import React, { useEffect } from 'react'
import logo from '../../Assets/Img/logo.svg'
// import menu from '../../Assets/Img/menu.svg'
import { handleUTMLink } from '../../utils'
import { useScroll } from '../ScrollProvider'
import { useNavigate } from 'react-router-dom'
const Header = () => {
    const { setHideFooterLogo } = useScroll();
    const navigate = useNavigate()

    useEffect(() => {

        const handleScroll = () => {
            const headerLogo = document.getElementById('header-logo');
            const footerLogo = document.getElementById('footer-logo');


            if (headerLogo && footerLogo) {
                const headerRect = headerLogo.getBoundingClientRect();
                const footerRect = footerLogo.getBoundingClientRect();
                if (footerRect.top - headerRect.bottom < 20) {
                    setHideFooterLogo(true);
                } else {
                    setHideFooterLogo(false);
                }
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [setHideFooterLogo]);



    return (

        <div className=' header-wrapper w-100 position-fixed top-0'>
            <div className='px-3 px-md-5 d-flex align-items-center justify-content-between'>
                <div className='logo-container cursor-pointer' onClick={() => {
                    navigate('/')
                }}>
                    <img src={logo} />
                </div>
                <div className='d-none d-lg-flex align-items-center'>
                    {/* <a href='https://player.gentimedia.com/' rel="noreferrer" target="_blank" className='nav-item mb-0 '>Listen on web</a> */}
                    <button className='btn genti-gradient_btn' onClick={() => handleUTMLink('https://gentiaudio.app.link')}>
                        Download the App
                    </button>

                </div>
                <div className='d-block d-lg-none '>
                    {/* <img alt='menu_icon' src={menu} /> */}
                    <button className='btn genti-gradient_btn mobile-header_btn' onClick={() => handleUTMLink('https://gentiaudio.app.link')}>
                        Download the App
                    </button>
                </div>
            </div >


        </div >
    )
}

export default Header