import React from 'react'
import originals from '../Assets/Img/new_originals.svg'
import { Row, Col } from 'reactstrap'
import { Slide } from "react-awesome-reveal";
import { handleUTMLink } from '../utils';
import { isMobile } from '../utils';
import mobileOriginals from '../Assets/Img/originals.png'

const Originals = () => {
    return (
        <section className=' originals-section section-container p'>
            <Row className='d-flex align-items-center genti-container-fluid mb-0 gy-3'>
                <Col sm='12' md='6'>
                    <Slide direction='left' fraction={1} triggerOnce>
                        <div className='pe-md-5 pe-0 section-img_wrapper'>
                            <img src={isMobile ? mobileOriginals : originals} alt='genti-img' />
                        </div>
                    </Slide>
                </Col>
                <Col sm='12' md='6'>
                    <div className=' section-text_wrapper'>
                        <h3 className='section-main_text mb-0'>Genti Originals</h3>
                        <p className='section-sub_text'>Listen to fresh, original stories exclusive to the Genti Audio Platform</p>
                        <div className='section-btn__wrapper'>
                            <button className='btn section-btn genti-gradient_btn' onClick={() => handleUTMLink('https://gentiaudio.app.link')}>Get Started</button>

                        </div>
                    </div>
                </Col>
            </Row>
        </section >
    )
}

export default Originals