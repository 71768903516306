import Hero from '../components/Hero';
import Originals from '../components/Originals';
import Languages from '../components/Languages';
import Offline from '../components/Offline';
import Footer from '../components/Footer';
function Home() {
    return (
        <div className="Genti-wrapper overlay">
            <Hero />
            <Originals />
            <Languages />
            <Offline />

            <Footer />
        </div>
    );
}

export default Home;
