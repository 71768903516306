import 'bootstrap/dist/css/bootstrap.css';
import './index.css'
import RouterConfig from './Router';
function App() {
  return (

    <RouterConfig />

  );
}

export default App;
