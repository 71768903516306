import React, { createContext, useState, useContext } from 'react';

const ScrollContext = createContext();

export const ScrollProvider = ({ children }) => {
    const [hideFooterLogo, setHideFooterLogo] = useState(false);

    const value = {
        hideFooterLogo,
        setHideFooterLogo,
    };

    return <ScrollContext.Provider value={value}>{children}</ScrollContext.Provider>;
};

export const useScroll = () => {
    return useContext(ScrollContext);
};