import React, { useState } from 'react'
import { faqs } from '../utils/faqs'
import SocialFooter from '../components/SocialFooter'
import { HiChevronUp, HiChevronDown } from "react-icons/hi";
import Header from '../components/Hero/Header';


const createMarkup = (html) => {
    return { __html: html }
}

const FAQ = ({ question, answer, active, handleClick }) => {
    return (
        <div className={`rounded faq-container p-md-4 p-2 mb-3  `} onClick={handleClick}>

            <div className='d-flex align-items-center justify-content-between'>
                <p className={`mb-0 faq-question`}>{question}</p>

                {
                    active ?
                        <div className='faq-icon_wrapper '>
                            <HiChevronUp size={25} color='#242525' />
                        </div> :
                        <div className='faq-icon_wrapper inactive'>
                            <HiChevronDown color='#fff' size={25} />
                        </div>
                }
            </div>
            {
                active && <p className={`text-white mt-3 faq-answer `} dangerouslySetInnerHTML={createMarkup(answer)}></p>
            }
        </div>
    )

}
const FAQs = () => {
    const [selectedFaq, setSelectedFaq] = useState(0)

    const handleClick = (index) => {
        setSelectedFaq(index)
    }
    return (
        <>
            <Header />
            <div className='faq-wrapper'>
                <h3 className='hero-main_text text-center mb-2' data-text='Frequently Asked Questions'>
                    Frequently Asked Questions</h3>
                <p className='text-white text-center'>We are here to help you with any questions.</p>
            </div>
            <div className='faqs-wrapper mt-5 mt-md-0 mb-5'>
                {
                    faqs.map((el, i) =>
                        <FAQ
                            question={el?.question}
                            answer={el?.answer}
                            active={selectedFaq === i}
                            handleClick={() => {
                                handleClick(i)
                                console.log(i)
                            }}
                        />
                    )
                }
            </div>
            <div className='mx-md-5 mb-md-5'>
                <SocialFooter />

            </div>

        </>

    )
}

export default FAQs