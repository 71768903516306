import Modal from "./Modal";
import React, { useState } from "react";


const About = () => {
  const [showless, setShowless] = useState(true);
  const [showModal, setShowModal] = useState(false);

  const handleClick = () => {
    setShowless(false);
    setShowModal(true);
  };
  const hideModal = () => setShowModal(false);

  return (
    <>
      <div className=" mb-4 about-us text-white ">
        <p>
          Genti is an audio media platform dedicated to showcasing African audio
          stories, dramas, educational materials and more in African languages
          and voices! Check these out on the Genti Audio mobile or web apps!
          <p className="mt-4">
            Genti currently has over 10,000 hours of audio content in 15
            languages collaborating with leading voice talent including Ahmed
            Isah Koko, Elma Mbadiwe, Chief Chiwetalu Agu, Uzor Arukwe, to name a
            few.
          </p>
        </p>

        <p className="mt-4">
          Our audio stories and programs come from an array of authors and
          publishers including Leye Adenle, Kiru Taye, Amah’s Heart of
          GranAmah’s Heart fame on Facebook, Tobi Loba,{" "}
          <a
            href="https://open.gentimedia.com/FAKN7esurwb/podcast/4"
            target="_blank"
          >
            Ọjị Abiala - Igbo Podcast
          </a>
          , Tomi Adesina, Centino, and Grey Continent, Hauwa A. Usman Jiddarh,
          BBC Media Action, ARDA DCI, Summayya Abdulkadir, Rahama A. Mohammed,
          Lafazi Communication, Population Media Center, Bible Society of
          Nigeria, Dawah Nigeria, Jerry Chiemeke, Sir Eriata Oribhabor, Double
          Cee Productions, Radio Nigeria, Deolu Bubble, HALI (Hallmark
          Leadership Initiative) to name but a few.
        </p>

        <button
          className="btn section-btn genti-gradient_btn"
          onClick={handleClick}
        >
          Read more
        </button>
      </div>

      {showModal ? <Modal showless setShowModal hideModal={hideModal} /> : ""}
    </>
  );
};

export default About;
