import React from "react";

function Modal({ showModal, hideModal }) {
  return (
    <div className="position relative">
      <div className="modale rounded position-fixed top-0 left-0 top-25 z-9999" >
        <div className=" mb-4 about-us !position-relative">
          <p>
            Genti is an audio media platform dedicated to showcasing African
            audio stories, dramas, educational materials and more in African
            languages and voices! Check these out on the Genti Audio mobile or
            web apps!
            <p className="mt-4">
              Genti currently has over 10,000 hours of audio content in 15
              languages collaborating with leading voice talent including Ahmed
              Isah Koko, Elma Mbadiwe, Chief Chiwetalu Agu, Uzor Arukwe, to name
              a few.
            </p>
          </p>

          <p className="mt-4">
            Our audio stories and programs come from an array of authors and
            publishers including Leye Adenle, Kiru Taye, Amah’s Heart of
            GranAmah’s Heart fame on Facebook, Tobi Loba,{" "}
            <a
              href="https://open.gentimedia.com/FAKN7esurwb/podcast/4"
              target="_blank"
            >
              Ọjị Abiala - Igbo Podcast
            </a>
            , Tomi Adesina, Centino, and Grey Continent, Hauwa A. Usman Jiddarh,
            BBC Media Action, ARDA DCI, Summayya Abdulkadir, Rahama A. Mohammed,
            Lafazi Communication, Population Media Center, Bible Society of
            Nigeria, Dawah Nigeria, Jerry Chiemeke, Sir Eriata Oribhabor, Double
            Cee Productions, Radio Nigeria, Deolu Bubble, HALI (Hallmark
            Leadership Initiative) to name but a few.
          </p>

          <p className="mt-4">
            Our local language content (including Pidgin stories) which spans
            across different genres is widely listened to as is our English
            content. Some highlights from categories include:
          </p>

          <p className="mt-6">
            <span className="fw-bold">Romance:</span> Some of our top romances
            include{" "}
            <a
              href="https://open.gentimedia.com/FAKN7esurwb/title/345"
              target="_blank"
            >
              Chronicles of a Runs Girl{" "}
            </a>
            – a 29-part audio series about Amaka – a university student and runs
            girl as she navigates life and hustle in Nigeria. Written by Leye
            Adenle and voiced by Elma Mbadiwe, you won’t be able to stop
            listening to this captivating story with intrigue, crime,
            friendships, betrayal and more. The full audio story is only
            available through the Genti Audio app. Then, we also have another
            Genti Audio exclusive -{" "}
            <a
              href="https://open.gentimedia.com/FAKN7esurwb/title/280"
              target="_blank"
            >
              Fola King{" "}
            </a>{" "}
            by Tomi Adesina – the writer of a Netflix No 1 Film – The Wait and
            the AMAA Award winning film Hakkunde. Part legal thriller, part
            romance, you also don’t want to miss this story voiced in a deep and
            sultry voice by Adami Osho. Love makes the world go round, right?
            You can’t miss Kiru Taye’s Essien Series now available on Genti
            Audio - from
            <a
              href="https://open.gentimedia.com/FAKN7esurwb/title/385"
              target="_blank"
            >
              {" "}
              Keeping Secrets
            </a>
            ,
            <a
              href="https://open.gentimedia.com/FAKN7esurwb/title/386"
              target="_blank"
            >
              {" "}
              Making Scandal
            </a>{" "}
            and
            <a
              href="https://open.gentimedia.com/FAKN7esurwb/title/387"
              target="_blank"
            >
              {" "}
              Riding Rebel
            </a>
            , the award-winning Kiru Taye – author and founder of Love Africa
            Press does what she does best – weaves a yarn of love and intrigue.
            Listen to Genti Originals such as
            <a
              href="https://open.gentimedia.com/FAKN7esurwb/title/365"
              target="_blank"
            >
              {" "}
              Toasting Love{" "}
            </a>
            where we sit down with The Shofs, the Anthonys, and many other
            couples to hear their love story. Looking for a dating show, we’ve
            also got you through “
            <a
              href="https://open.gentimedia.com/FAKN7esurwb/title/393"
              target="_blank"
            >
              Calling Cupid{" "}
            </a>
            ” where strangers who have never seen each other, talk and decide
            whether they want to keep dating. Get your earphones and listen
            while on the go!
            <p className="mt-4">
              <span className="fw-bold">Pidgin Content and Radio Dramas:</span>{" "}
              We have{" "}
              <a
                href="https://open.gentimedia.com/FAKN7esurwb/title/212"
                target="_blank"
              >
                Kusi – a Pidgin story{" "}
              </a>{" "}
              from Amah’s Heart about Kusimala wey dey hustle for im and im
              family. Also,
              <a
                href="https://open.gentimedia.com/FAKN7esurwb/title/349"
                target="_blank"
              >
                {" "}
                My Good No Go Kill me{" "}
              </a>
              , also from Amah’s Heart. We also have several radio dramas
              including
              <a
                href="https://open.gentimedia.com/FAKN7esurwb/title/249"
                target="_blank"
              >
                {" "}
                Safe Journey{" "}
              </a>
              from Radio Nigeria about Shaky Shaky and Alao the master driver,
              <a
                href="https://open.gentimedia.com/FAKN7esurwb/title/337"
                target="_blank"
              >
                {" "}
                Story Story – Voices from the Market{" "}
              </a>
              by BBC Media Action,
              <a
                href="https://open.gentimedia.com/FAKN7esurwb/title/340"
                target="_blank"
              >
                {" "}
                Gatanan Gatanan Ku{" "}
              </a>
              ,
              <a
                href="https://open.gentimedia.com/FAKN7esurwb/title/339"
                target="_blank"
              >
                {" "}
                Madubi{" "}
              </a>
              ,
              <a
                href="https://open.gentimedia.com/FAKN7esurwb/title/288"
                target="_blank"
              >
                {" "}
                Jolokoto{" "}
              </a>
              (a Yoruba Drama from Population Media Center), and different
              programs from ARDA DCI including
              <a
                href="https://open.gentimedia.com/FAKN7esurwb/title/95"
                target="_blank"
              >
                {" "}
                Boomerang
              </a>
              ,
              <a
                href="https://open.gentimedia.com/FAKN7esurwb/title/85"
                target="_blank"
              >
                {" "}
                Yar Baiwa{" "}
              </a>
              ,
              <a
                href="https://open.gentimedia.com/FAKN7esurwb/title/81"
                target="_blank"
              >
                {" "}
                Hope Town{" "}
              </a>
              ,
              <a
                href="https://open.gentimedia.com/FAKN7esurwb/title/105"
                target="_blank"
              >
                {" "}
                Day Don Break{" "}
              </a>
              and much more. We have Pidgin romance from Deolu Bubble. We also
              have the Genti Pidgin Writing Competition Winning Stories on audio
              voices by upcoming voice talent and leading actors like Elma
              Mbadiwe and Uzor Arukwe, and coming soon – different radio drama
              and stories from Correct FM. O por, O por, O por! No be small
              thing! We get beta tori for pidgin. No miss am!
            </p>
            <p>
              <span className="fw-bold">Comedy:</span> One of the top stories
              here is the audio version of 225 Katakata Street from Centino.
              This story is a Top 3 Story in the literature section of
              Nairaland. With over 1.5 million views on Nairaland,{" "}
              <a
                href="https://open.gentimedia.com/FAKN7esurwb/title/331"
                target="_blank"
              >
                {" "}
                225 Katakata Street{" "}
              </a>{" "}
              has it all – cry, laughter, and Naija politics join as we see the
              life of neighbours in a face me I face you in Lagos. On Genti, we
              go beyond the stories on Nairaland to Part 2 and (soon to come)
              Part 3 of 225 Katakata Street.
            </p>
            <p className="mt-4">
              <span className="fw-bold">Religion and Spirituality:</span> Listen
              to the
              <a
                href="https://open.gentimedia.com/FAKN7esurwb/title/243"
                target="_blank"
              >
                {" "}
                Bible in Pidgin{" "}
              </a>
              and the Bible in your language as well as to the
              <a
                href="https://open.gentimedia.com/FAKN7esurwb/title/92"
                target="_blank"
              >
                {" "}
                Quran in Yoruba{" "}
              </a>
              and
              <a
                href="https://open.gentimedia.com/FAKN7esurwb/title/91"
                target="_blank"
              >
                {" "}
                Quran in Arabic
              </a>
              . You can listen to the
              <a
                href="https://open.gentimedia.com/FAKN7esurwb/title/243"
                target="_blank"
              >
                {" "}
                Bible in Pidgin{" "}
              </a>
              ,
              <a
                href="https://open.gentimedia.com/FAKN7esurwb/title/235"
                target="_blank"
              >
                {" "}
                Bible in Yoruba language{" "}
              </a>
              ,
              <a
                href="https://open.gentimedia.com/FAKN7esurwb/title/234"
                target="_blank"
              >
                {" "}
                Bible in Hausa (Littafi Mai Tsarki){" "}
              </a>
              ,
              <a
                href="https://open.gentimedia.com/FAKN7esurwb/title/380"
                target="_blank"
              >
                {" "}
                Bible in Nupe{" "}
              </a>
              ,
              <a
                href="https://open.gentimedia.com/FAKN7esurwb/title/357"
                target="_blank"
              >
                {" "}
                Bible in Ebira{" "}
              </a>
              ,
              <a
                href="https://open.gentimedia.com/FAKN7esurwb/title/237"
                target="_blank"
              >
                {" "}
                Bible in Edo{" "}
              </a>
              ,
              <a
                href="https://open.gentimedia.com/FAKN7esurwb/title/238"
                target="_blank"
              >
                {" "}
                Bible in Urhobo{" "}
              </a>
              ,
              <a
                href="https://open.gentimedia.com/FAKN7esurwb/title/239"
                target="_blank"
              >
                {" "}
                Bible in Igala{" "}
              </a>
              ,
              <a
                href="https://open.gentimedia.com/FAKN7esurwb/title/240"
                target="_blank"
              >
                {" "}
                Bible in Kalabari{" "}
              </a>
              ,
              <a
                href="https://open.gentimedia.com/FAKN7esurwb/title/241"
                target="_blank"
              >
                {" "}
                Bible in Tiv{" "}
              </a>
              ,
              <a
                href="https://open.gentimedia.com/FAKN7esurwb/title/242"
                target="_blank"
              >
                {" "}
                Bible in Okrika{" "}
              </a>
              ,
              <a
                href="https://open.gentimedia.com/FAKN7esurwb/title/244"
                target="_blank"
              >
                {" "}
                Bible in Bura{" "}
              </a>
              ,
              <a
                href="https://open.gentimedia.com/FAKN7esurwb/title/245"
                target="_blank"
              >
                {" "}
                Bible in Efik{" "}
              </a>
              and
              <a
                href="https://open.gentimedia.com/FAKN7esurwb/title/93"
                target="_blank"
              >
                {" "}
                The Holy Bible in English (King James Version){" "}
              </a>
              . Also check out sermons from the likes of Sam Adeyemi Ministries.
              Do your devotional while going to work by listening to the Bible
              or Quran and other devotional and sermon podcasts like
              <a
                href="https://open.gentimedia.com/FAKN7esurwb/podcast/14"
                target="_blank"
              >
                {" "}
                Apostle Joshua Selman{" "}
              </a>
              and
              <a
                href="https://open.gentimedia.com/FAKN7esurwb/podcast/378"
                target="_blank"
              >
                {" "}
                Mufti Menk
              </a>
            </p>
            <p className="mt-4">
              <span className="fw-bold">Children Stories: </span> Check out
              <a
                href="https://open.gentimedia.com/FAKN7esurwb/title/263"
                target="_blank"
              >
                {" "}
                Tales by Moonlight
              </a>
              Folktales collection for African folktales and stories in English.
              We also have a collection of stories in local languages as well
              such as a Children Story series
              <a
                href="https://open.gentimedia.com/FAKN7esurwb/title/261"
                target="_blank"
              >
                {" "}
                – 5 Shot Stories in Yoruba
              </a>
              , among others.
            </p>
            <p className="mt-4">
              <span className="fw-bold">Language Learning:</span> Do you want to
              <a
                href="https://open.gentimedia.com/FAKN7esurwb/title/99"
                target="_blank"
              >
                {" "}
                Learn Igbo
              </a>
              ,
              <a
                href="https://open.gentimedia.com/FAKN7esurwb/title/246"
                target="_blank"
              >
                {" "}
                Learn Yoruba{" "}
              </a>
              or
              <a
                href="https://open.gentimedia.com/FAKN7esurwb/title/100"
                target="_blank"
              >
                {" "}
                Learn Hausa
              </a>
              ? Genti Audio has got you! On Genti, you can find follow along
              language learning courses for each language with multiple Yoruba
              courses including
              <a
                href="https://open.gentimedia.com/FAKN7esurwb/title/97"
                target="_blank"
              >
                {" "}
                Yoruba Basic Course
              </a>
              ,
              <a
                href="https://open.gentimedia.com/FAKN7esurwb/title/98"
                target="_blank"
              >
                {" "}
                Yoruba Intermediate Course{" "}
              </a>
              as well as Yoruba Lessons in Spanish (
              <a
                href="https://open.gentimedia.com/FAKN7esurwb/title/247"
                target="_blank"
              >
                {" "}
                Curso de Yoruba en Español{" "}
              </a>
              ) and Yoruba Lessons in Portuguese (
              <a
                href="https://open.gentimedia.com/FAKN7esurwb/title/248"
                target="_blank"
              >
                {" "}
                Curso de Iorubá em Português
              </a>
              ).
            </p>
            <p className="mt-4">
              <span className="fw-bold mb-0">Education</span>
              <p>
                {" "}
                Doing JAMB or WAEC Revision? Check out the Genti Category
                specifically for that! There you can find our
                <a
                  href="https://open.gentimedia.com/FAKN7esurwb/title/348"
                  target="_blank"
                >
                  {" "}
                  Christian Religious Knowledge{" "}
                </a>
                WAEC and GCE Guide, WAEC
                <a
                  href="https://open.gentimedia.com/FAKN7esurwb/title/308"
                  target="_blank"
                >
                  {" "}
                  Revision material and Summary for Second Class Citizen{" "}
                </a>
                by Buchi Emecheta, audio summary and revision guide for to
                listen to
                <a
                  href="https://open.gentimedia.com/FAKN7esurwb/title/306"
                  target="_blank"
                >
                  {" "}
                  The Life Changer{" "}
                </a>
                by Khadija Abubakar Jalli (JAMB English Text), and audio version
                of a number of other text for literature students like
                <a
                  href="https://open.gentimedia.com/FAKN7esurwb/title/270"
                  target="_blank"
                >
                  {" "}
                  Wuthering Heights{" "}
                </a>
                and more.
              </p>
            </p>
            <p className="mt-4">
              <span className="fw-bold">Hausa Content:</span> Listen to a range
              of content from authors including Summayya Abdulkadir, Rahama A.
              Mohammed, Hauwa A. Usman Jiddarh and more including
              <a
                href="https://open.gentimedia.com/FAKN7esurwb/title/295"
                target="_blank"
              >
                {" "}
                Auren Zumunci
              </a>
              ,
              <a
                href="https://open.gentimedia.com/FAKN7esurwb/title/294"
                target="_blank"
              >
                {" "}
                Matar Gado
              </a>
              ,
              <a
                href="https://open.gentimedia.com/FAKN7esurwb/title/362"
                target="_blank"
              >
                {" "}
                Auren Kwangila
              </a>
              ,
              <a
                href="https://open.gentimedia.com/FAKN7esurwb/title/363"
                target="_blank"
              >
                {" "}
                Misbah
              </a>
              . We have several Hausa novels (litaffin hausa) in audio narrated
              by the popular Hausa audiobook narrator – Ahmad Isah Koko and new
              ones released each month. In addition, listen to Hausa novels in
              audio in form of radio dramas from BBC Media Action (such as
              <a
                href="https://open.gentimedia.com/FAKN7esurwb/title/340"
                target="_blank"
              >
                {" "}
                Gatanan Gatanan Ku
              </a>
              , and
              <a
                href="https://open.gentimedia.com/FAKN7esurwb/title/339"
                target="_blank"
              >
                {" "}
                Madubi
              </a>
              to name a few).
              <p className="mt-4">
                <p>
                  <span className="fw-bold">Saurari Littattafan Hausa:</span>{" "}
                  Saurari bayanai da dama daga marubutan da suka hada da
                  Summayya Abdulkadir, Rahama A. Mohammed, Hauwa A. Usman
                  Jiddarh da dai sauransu ciki har da Auren Zumunci, Matar Gado,
                  Auren Kwangila, Misbah. Muna da littafan hausa da dama masu
                  dauke da littafan soyayya masu tarin yawa a cikin audio wanda
                  shahararren marubucin littafin audio na Hausa - Ahmad Isah
                  Koko ya rawaito da wasu sabbi a duk wata. Bugu da kari, ku
                  saurari litattafan Hausa novel a cikin sauti ta hanyar wasan
                  kwaikwayo na rediyo daga BBC Media Action (kamar Gatanan
                  Gatanan Ku, da Madubi da sauransu).
                </p>
                <p>
                  <span className="fw-bold">
                    Original Content and Documentaries
                  </span>{" "}
                  – You don’t want to miss the Genti Original documentaries
                  including
                  <a
                    href="https://open.gentimedia.com/FAKN7esurwb/title/285"
                    target="_blank"
                  >
                    {" "}
                    The Contender on the Rise of Peter Obi{" "}
                  </a>
                  ahead of the 2023 Elections (available in English and in Hausa
                  as
                  <a
                    href="https://open.gentimedia.com/FAKN7esurwb/title/285"
                    target="_blank"
                  >
                    {" "}
                    Daukakar Darajar Peter Obi
                  </a>
                  . Also out is Japa Diaries on the latest Japa wave where we
                  talk to a doctor in the UK, someone who did Canada japa
                  through vocational route as an older person, a Malaysia japa,
                  and many more. We hear from experts and talk to the friends
                  and family they left behind. Don’t miss this exploration of
                  the effect of japa in today’s Nigeria.
                </p>
                <p>
                  We keep adding more. Stay tuned to Genti for more short
                  stories. Download the app, check out content on the home page
                  and explore more titles through the search and categories on
                  the explore page!
                </p>
              </p>
            </p>
          </p>

          <button
            className="btn genti-gradient_btn"
            onClick={hideModal}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
}

export default Modal;
