import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Home from './Pages/Home'
import FAQs from './Pages/FAQs'
import Coins from './Pages/Coins'

const RouterConfig = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route exact path='/' element={<Home />} />
                <Route exact path='/faqs' element={<FAQs />} />
                <Route exact path='/how-coins-work' element={<Coins />} />
            </Routes>
        </BrowserRouter>
    )
}
export default RouterConfig