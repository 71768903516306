import React, { useState } from 'react'
import SocialFooter from '../components/SocialFooter'
import Header from '../components/Hero/Header';
import coin_icon from '../Assets/Img/coin.png'
import bag_icon from '../Assets/Img/bag.png'
import headphone_icon from '../Assets/Img/headphone.png'
import lock_icon from '../Assets/Img/lock.png'
import arrow_icon from '../Assets/Img/arrow.png'
import step1 from '../Assets/Img/step1.svg'
import step2 from '../Assets/Img/step2.svg'
import step3 from '../Assets/Img/step3.svg'
import step4 from '../Assets/Img/step4.svg'
import step5 from '../Assets/Img/step5.svg'
import step1_mobile from '../Assets/Img/step1.png'
import step2_mobile from '../Assets/Img/step2.png'
import step3_mobile from '../Assets/Img/step3.png'
import step4_mobile from '../Assets/Img/step4.png'
import step5_mobile from '../Assets/Img/step5.png'
import { Row, Col } from 'reactstrap'
import { Slide } from "react-awesome-reveal";
import { isMobile } from '../utils';






const Coins = () => {

    return (
        <div className='coins_page'>
            <Header />
            <div className='coins-header_wrapper'>
                <h3 className='hero-main_text text-center mb-0' data-text='Frequently Asked Questions'>
                    Genti Coins</h3>
                <img src={coin_icon} alt='icon' className='object-cover mt-3 mb-5 coin_icon' />
                <p className='text-white text-center fw-semibold fs-4 '>How it works</p>
                <div className='d-flex align-items-center justify-content-center gap-md-5 gap-2 w-100 px-3 px-md-5 genti-coins-wrapper py-3 '>
                    <div className='d-flex align-items-center justify-content-center flex-column'>
                        <img src={bag_icon} alt='icon' className='object-cover  icon' />
                        <p className='fs-md-5  text-center  mb-0 text-white coin-description py-md-0 py-2'>Get Coins</p>
                    </div>
                    <img src={arrow_icon} alt='icon' className='object-cover  arrow-icon' />
                    <div className='d-flex align-items-center justify-content-center flex-column'>
                        <img src={headphone_icon} alt='icon' className='object-cover  icon' />
                        <p className='fs-md-5  text-center  mb-0 text-white coin-description py-md-0 py-2'>Unlock Episodes</p>

                    </div>
                    <img src={arrow_icon} alt='icon' className='object-cover  arrow-icon' />
                    <div className='d-flex align-items-center justify-content-center flex-column'>
                        <img src={lock_icon} alt='icon' className='object-cover  icon' />
                        <p className='fs-md-5  text-center  mb-0 text-white coin-description py-md-0 py-2'>Start Listening</p>

                    </div>
                </div>
            </div>



            <section className='  my-md-5 my-4 d-flex flex-column gap-4 gap-md-5 justify-content-center align-items-center'>

                <Row className='d-flex  genti-container-fluid mb-0 gx-3 gx-md-2 align-items-center'>
                    <Col className=''>
                        <Slide direction='left' fraction={0.1} triggerOnce>
                            <div className='pe-md-5 pe-0 section-img_wrapper mb-0'>
                                <img src={isMobile ? step1_mobile : step1} alt='genti-img' />
                            </div>
                        </Slide>

                    </Col>
                    <Col className=''>
                        <div className=' coin-text_wrapper d-flex flex-column'>
                            <div>
                                <p className='step-number mb-0'>01</p>

                            </div>
                            <p className='text-white guide_text mt-md-5 pt-md-2'>Go to a title (audio story/ audiobook) you want to listen to</p>
                        </div>
                    </Col>


                </Row>
                <Row className='d-flex  genti-container-fluid mb-0 gx-3 gx-md-2 align-items-center'>
                    <Col className=''>
                        <div className='pe-md-5 pe-0 coin-text_wrapper text-right d-flex flex-column justify-content-end align-items-end'>
                            <div>
                                <p className='step-number mb-0 text-right '>02</p>

                            </div>
                            <p className='text-white guide_text mt-md-5 pt-md-2 text-end'>Select the locked episode you want to listen to</p>
                        </div>
                    </Col>
                    <Col >
                        <Slide direction='right' fraction={0.1} triggerOnce>
                            <div className=' pe-0 section-img_wrapper mb-0'>
                                <img src={isMobile ? step2_mobile : step2} alt='genti-img' />
                            </div>
                        </Slide>

                    </Col>



                </Row>
                <Row className='d-flex  genti-container-fluid mb-0 gx-3 gx-md-2 align-items-center'>
                    <Col className=''>
                        <Slide direction='left' fraction={0.1} triggerOnce>
                            <div className='pe-md-5 pe-0 section-img_wrapper mb-0'>
                                <img src={isMobile ? step4_mobile : step4} alt='genti-img' />
                            </div>
                        </Slide>

                    </Col>
                    <Col className=''>
                        <div className=' coin-text_wrapper d-flex flex-column'>
                            <div>
                                <p className='step-number mb-0'>03</p>

                            </div>
                            <p className='text-white guide_text mt-md-5 pt-md-2'>Unlock stories. If your coins are insufficient, follow the prompt to buy coins</p>
                        </div>
                    </Col>


                </Row>
                <Row className='d-flex  genti-container-fluid mb-0 gx-3 gx-md-2 align-items-center'>
                    <Col className=''>
                        <div className='pe-md-5 pe-0 coin-text_wrapper  d-flex flex-column justify-content-end align-items-end'>
                            <div>
                                <p className='step-number mb-0'>04</p>

                            </div>
                            <p className='text-white guide_text mt-md-5 pt-md-2 text-end'>Start listening or download unlocked episodes and listen anytime, anywhere!</p>
                        </div>
                    </Col>
                    <Col >
                        <Slide direction='right' fraction={0.1} triggerOnce>
                            <div className=' pe-0 section-img_wrapper mb-0'>
                                <img src={isMobile ? step5_mobile : step5} alt='genti-img' />
                            </div>
                        </Slide>

                    </Col>



                </Row>
                {/* <Row className='d-flex  genti-container-fluid mb-0 gx-3 gx-md-2 align-items-center'>
                    <Col className=''>
                        <Slide direction='left' fraction={0.1} triggerOnce>
                            <div className='pe-md-5 pe-0 section-img_wrapper mb-0'>
                                <img src={isMobile ? step5_mobile : step5} alt='genti-img' />
                            </div>
                        </Slide>

                    </Col>
                    <Col className=''>
                        <div className=' coin-text_wrapper d-flex flex-column'>
                            <div>
                                <p className='step-number mb-0'>05</p>

                            </div>
                            <p className=' text-white guide_text mt-md-5 pt-md-2'>Start listening or download unlocked episodes and listen anytime, anywhere!</p>
                        </div>
                    </Col>


                </Row> */}
            </section>

            <div className='mx-md-5 mb-md-5'>
                <SocialFooter />

            </div>

        </div>

    )
}

export default Coins